<template>
    <div class="common-comp-count">
        <div class="btn">
            <div class="down" @click="down"> - </div>
        </div>
        <div class="number">
            <el-input type="number" v-model="num" @input="onChange" />
        </div>
        <div class="btn">
            <div class="up" @click="up"> + </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Count',
    props: {
        count: {
            type: Number,
            default: 0
        },
        max: {
            type: Number,
            default: 10000
        }
    },
    model: {
      prop: 'count',
      event: 'change'
    },
    data() {
        return {
            num: this.count
        }
    },
    watch: {
        num(v) {
            const value = parseInt(v)
            this.$emit('change', value)
        },
        count(v) {
            this.num = v
        }
    },
    methods: {
        up() {
            if (this.num === this.max) {
                return
            }
            this.num = this.num * 1 + 1
        },
        down() {
            if (this.num === 0) {
                return
            }
            this.num = this.num * 1 - 1
        },
        onChange(v) {
            if (v == '') {
                this.num = 0
                return
            }
            if (v >= this.max) {
                this.num = this.max
                return
            }
            let license_num = v;
            license_num = license_num.replace(/[^\d]/g, '')
            if (license_num.indexOf('.') < 0 && license_num != '') {
                license_num = parseInt(license_num);
            }
            this.num = license_num
        }
    }
}
</script>

<style lang="less">
.common-comp-count {
    display: flex;
    height: 36px;
    box-sizing: border-box;
    .number {
        background: #fff;
        font-family: ChalkboardSEBold;
        text-align: center;
        height: 36px;
        font-size: 18px;
        flex: 1;
        color: #000;
        margin: 0 5px;
        padding: 0 12px;
        border-radius: 2px;
        display: flex;
        .el-input {
            width: 100%;
            display: flex;
            .el-input__inner {
                flex: 1;
                border: none;
                height: 100%;
                font-family: ChalkboardSEBold;
                color: #000;
                text-align: center;
                font-size: 18px;
                line-height: 32px;
                padding: 0;
                width: 100%;
                input {
                    width: 100%;
                }
            }
        }
    }
    .btn {
        display: flex;
        width: 36px;
        div {
            display: flex;
            justify-content: center;
            cursor: pointer;
            background-color: #2C99F1;
            flex: 1;
            border-radius: 5px;
            overflow: hidden;
            font-size: 28px;
            line-height: 30px;
        }
    }
}
</style>
